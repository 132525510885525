import escapeHtml from 'escape-html';
import { Descendant, Editor, Text } from 'slate';
import { jsx } from 'slate-hyperscript';

import { CustomText } from './slateTypes.js';

const serializeToHtml = (node: Descendant): Descendant[] | string => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    } else if (node.italic) {
      string = `<em>${string}</em>`;
    } else if (node.underline) {
      string = `<span style="text-decoration: underline">${string}</span>`;
    }
    return string;
  }

  const children = node.children.map((n) => serializeToHtml(n)).join('');

  switch (node.type) {
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    case 'list-item':
      return `<li>${children}</li>`;
    default:
      return children;
  }
};

type NullableDescendant = Descendant | null;

const deserializeFromHtml = (
  el: Node,
  markAttributes: Omit<CustomText, 'text'> = {},
): NullableDescendant | NullableDescendant[] | Editor => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  }
  if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  if (el.nodeName === 'STRONG') {
    nodeAttributes.bold = true;
  } else if (el.nodeName === 'EM') {
    nodeAttributes.italic = true;
  } else if (
    el.nodeName === 'SPAN' &&
    (el as HTMLElement).style?.textDecoration === 'underline'
  ) {
    nodeAttributes.underline = true;
  }

  const children = Array.from(el.childNodes)
    .map((node: ChildNode) => deserializeFromHtml(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children);
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    default:
      return jsx('fragment', {}, children);
  }
};

export const serializeToHtmlString = (nodes: Descendant[]): string =>
  nodes.map((node) => serializeToHtml(node)).join('');

export const deserializeFromHtmlString = (content?: string): Descendant[] => {
  const defaultBlankContent = getDefaultBlankContent();
  if (!content?.length) {
    return defaultBlankContent;
  }

  // If initialContent does not contain any HTML tags, return it as plain text.
  const hasHtmlTags = /<\/?[a-z][\s\S]*>/i.test(content);
  if (!hasHtmlTags) {
    return [{ type: 'paragraph' as const, children: [{ text: content }] }];
  }

  const domInput = new DOMParser().parseFromString(content, 'text/html');
  const deserialized = deserializeFromHtml(domInput.body) as Descendant[];
  if (deserialized != null) {
    return deserialized;
  }
  return defaultBlankContent;
};

export const getDefaultBlankContent = (): Descendant[] => [
  { type: 'paragraph' as const, children: [{ text: '' }] },
];
