export const toutTypeFeaturedPricing = 'FEAPR';
export const toutTypeVideoIntro = 'VDINT';
export const toutTypePromoLink = 'PROLI';

export const avatarPlaceholder =
  'https://thrivsports.s3.us-east-1.amazonaws.com/assets/images/avatars/default_user_noimage.jpg';

export const backgroundPlaceholder =
  'https://s3.amazonaws.com/golfmds/gmd/images/weirdpicture.jpg';

export const publicPricingAppendPercentage = 0.2;
