export const replaceUrlEncodedEntities = (input: string) =>
  input.replace(/&#039;/g, "'").replace(/&amp;/g, '&');

export const formatAsCurrency = (
  input: number,
  options?: Intl.NumberFormatOptions,
) => {
  const usDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  });
  return usDollar.format(input);
};

export const slugify = (input: string) =>
  input.toLowerCase().replace(/\s+/g, '-');

export const deslugify = (input: string) =>
  input.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());

// What a hack!
export const removePriceFromName = (input: string) => {
  const regex = /\s*for \$\d+(?:\.\d{2})?/g;
  return input.replace(regex, '');
};
