import './richTextEditor.scss';

import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';

import { RichTextEditorToolbar } from './RichTextEditorToolbar.js';
import { getDefaultBlankContent } from './serialize.js';

export type RichTextEditorProps = {
  initialContent?: Descendant[];
  cssClasses?: string[];
  onChange?: (value: Descendant[]) => void;
};

export const RichTextEditor: React.FC<RichTextEditorProps> = forwardRef(
  ({ initialContent, cssClasses, onChange }, ref) => {
    const [editor] = useState(() => withReact(withHistory(createEditor())));

    const initialValue = initialContent ?? getDefaultBlankContent();

    const renderElement = useCallback(
      (props: RenderElementProps) => <Element {...props} />,
      [],
    );
    const renderLeaf = useCallback(
      (props: RenderLeafProps) => <Leaf {...props} />,
      [],
    );

    useImperativeHandle(ref, () => ({
      focus: () => {
        ReactEditor.focus(editor);
      },
    }));

    let cssParsedClasses = 'ts-rich-text-editor';
    if (cssClasses?.length) {
      cssParsedClasses += ` ${cssClasses.join(' ')}`;
    }

    // Render the Slate context.
    return (
      <div className="ts-rich-text-editor-container">
        <Slate
          editor={editor}
          initialValue={initialValue}
          onValueChange={onChange}
        >
          <RichTextEditorToolbar />
          <div className={cssParsedClasses}>
            <Editable renderElement={renderElement} renderLeaf={renderLeaf} />
          </div>
        </Slate>
      </div>
    );
  },
);

const Element: React.FC<RenderElementProps> = ({
  attributes,
  children,
  element,
}) => {
  const style = {};
  switch (element.type) {
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf: React.FC<RenderLeafProps> = ({ attributes, children, leaf }) => {
  let newChildren = children;
  if (leaf.bold) {
    newChildren = <strong>{children}</strong>;
  }
  if (leaf.italic) {
    newChildren = <em>{children}</em>;
  }
  if (leaf.underline) {
    newChildren = <u>{children}</u>;
  }

  return <span {...attributes}>{newChildren}</span>;
};
