import './popover.scss';

import React, { useEffect, useState } from 'react';
import { Popover, PopoverProps } from 'react-tiny-popover';

export type HoverPopoverProps = Omit<PopoverProps, 'isOpen' | 'content'> & {
  content: JSX.Element;
};

/**
 * This supports hover events on web, and click events on mobile.
 */
export const HoverPopover: React.FC<HoverPopoverProps> = ({
  children,
  ...props
}) => {
  const onMouseLeaveToleranceMs = 250;

  // Keep track of hover states on both the triggering element AND the popover element.
  const [hoveringOnTriggerElement, setHoveringOnTriggerElement] =
    useState(false);
  const [hoveringOnPopoverElement, setHoveringOnPopoverElement] =
    useState(false);

  const [popoverVisible, setPopoverVisible] = useState(false);

  // Show the popover if either element is hovered over, but hide if BOTH elements
  // are no longer hovered over
  useEffect(() => {
    if (!hoveringOnTriggerElement && !hoveringOnPopoverElement) {
      setPopoverVisible(false);
    } else if (hoveringOnTriggerElement || hoveringOnPopoverElement) {
      setPopoverVisible(true);
    }
  }, [hoveringOnPopoverElement, hoveringOnTriggerElement]);

  const { content, ...otherProps } = props;

  const wrappedContent = (
    <div
      className="hover-popover-content"
      onMouseEnter={() => setHoveringOnPopoverElement(true)}
      onMouseLeave={() =>
        setTimeout(
          () => setHoveringOnPopoverElement(false),
          onMouseLeaveToleranceMs,
        )
      }
    >
      {content}
    </div>
  );

  return (
    <Popover
      isOpen={popoverVisible}
      reposition={true}
      boundaryElement={window.document.body}
      onClickOutside={() => setPopoverVisible(false)}
      content={wrappedContent}
      {...otherProps}
    >
      <div
        className="hover-popover-container"
        onClick={() => setPopoverVisible(!popoverVisible)}
        onMouseEnter={() => setHoveringOnTriggerElement(true)}
        onMouseLeave={() =>
          setTimeout(
            () => setHoveringOnTriggerElement(false),
            onMouseLeaveToleranceMs,
          )
        }
      >
        {children}
      </div>
    </Popover>
  );
};
