import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import React, { PropsWithChildren, useCallback } from 'react';
import { Popover } from 'react-tiny-popover';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { BlockType, CustomTextType, listTypes } from './slateTypes.js';

export const RichTextEditorToolbar: React.FC = () => (
  <div className="ts-rich-text-editor-toolbar-container">
    <div className="toolbar-group">
      <MarkButton format="bold" icon="bold" />
      <MarkButton format="italic" icon="italic" />
      <MarkButton format="underline" icon="underline" />
    </div>
    <div className="toolbar-group">
      <BlockButton format="bulleted-list" icon="list-ul" />
      <BlockButton format="numbered-list" icon="list-ol" />
    </div>
    <div className="toolbar-group">
      <EmojiSelectButton />
    </div>
  </div>
);

type ButtonProps = PropsWithChildren<{
  active: boolean;
  [key: string]: unknown;
}>;

const Button = React.forwardRef<HTMLSpanElement, ButtonProps>(
  ({ className, active, reversed, ...props }, ref) => (
    <span
      className={`toolbar-button ${active ? 'toolbar-button-active' : ''}`}
      {...props}
      ref={ref}
    />
  ),
);

type BlockButtonProps = {
  format: BlockType;
  icon: string;
};

const BlockButton: React.FC<BlockButtonProps> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <i className={`fa fa-${icon}`} />
    </Button>
  );
};

type MarkButtonProps = {
  format: CustomTextType;
  icon: string;
};

const MarkButton: React.FC<MarkButtonProps> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <i className={`fa fa-${icon}`} />
    </Button>
  );
};

const EmojiSelectButton: React.FC = () => {
  const editor = useSlate();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleEmojiSelect = useCallback(
    (emojiData: EmojiClickData) => {
      setIsOpen(false);
      editor.insertText(emojiData.emoji);
    },
    [editor],
  );

  return (
    <Popover
      isOpen={isOpen}
      positions={['bottom']}
      onClickOutside={() => setIsOpen(false)}
      containerStyle={{ padding: '0px', border: 'none' }}
      content={<EmojiPicker onEmojiClick={handleEmojiSelect} />}
    >
      <Button
        active={isOpen}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="emoji-select">😄</i>
      </Button>
    </Popover>
  );
};

const toggleBlock = (editor: Editor, format: BlockType) => {
  const isActive = isBlockActive(editor, format);
  const isList = listTypes.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      listTypes.includes(n.type),
    split: true,
  });

  let type = format;
  if (isActive) {
    type = 'paragraph';
  } else if (isList) {
    type = 'list-item';
  }

  Transforms.setNodes<SlateElement>(editor, { type });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: Editor, format: CustomTextType) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: Editor, format: BlockType) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    }),
  );

  return !!match;
};

const isMarkActive = (editor: Editor, format: CustomTextType) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
