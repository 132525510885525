import type { BaseEditor, Descendant } from 'slate';
import type { ReactEditor } from 'slate-react';

// This is an exhaustive definition of all the rich text customizations we support

export type BulletedListElement = {
  type: 'bulleted-list';
  children: Descendant[];
};
export type ListItemElement = { type: 'list-item'; children: Descendant[] };

export type NumberedListElement = {
  type: 'numbered-list';
  children: Descendant[];
};
export type ParagraphElement = { type: 'paragraph'; children: Descendant[] };
export type LineBreakElement = { type: 'line-break'; children: Descendant[] };

export type CustomElement =
  | BulletedListElement
  | ListItemElement
  | NumberedListElement
  | ParagraphElement;

export type BlockType = CustomElement['type'];

export const listTypes: BlockType[] = [
  'bulleted-list',
  'numbered-list',
] as const;

export type CustomText = {
  text: string;
  bold?: true;
  italic?: true;
  underline?: true;
};

export type CustomTextType = Exclude<keyof CustomText, 'text'>;

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}
